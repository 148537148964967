import { ITabData } from "../Common/Type/TypeCommon";
import { ITreeDataObj } from "../Common/Components/Tree/Tree";

export const tabData: ITabData[] = [
  {
    id: "servers",
    displayName: "Servers",
    path: "/",
    indexNumber: 0,
  },
];
export const OpcServerStatus: { [key: string]: number } = {
  Connected: 0,
  Connecting: 1,
  Disconnected: 2,
};

export const serversGridFixedWidth = 125;
export const configuredDataGridFixedWidth = 95;
export const variableGridFixedWidth = 430;
export const methodGridFixedWidth = 430;
export const availableTypes = {
  uaDataType: "UADataType",
  uaMethod: "UAMethod",
  uaObject: "UAObject",
  uaObjectType: "UAObjectType",
  uaReferenceType: "UAReferenceType",
  uaVariable: "UAVariable",
  uaVariableType: "UAVariableType",
  uaView: "UAView",
  root: "Root",
};
export const helpPageTreeData: ITreeDataObj[] = [
  {
    id: "%1.introduction%",
    displayName: "Introduction",
    rawData: { nodeId: "%1.introduction%" } as any,
    isExpandable: false,
    isExpanded: false,
    isVisible: true,
    isTitle: false,
    level: 1,
  },
  {
    id: "%2.edgenius%",
    displayName: "Working with Edgenius Configuration Utility",
    rawData: { nodeId: "%2.edgenius%" } as any,
    isExpandable: true,
    isExpanded: true,
    isVisible: true,
    isTitle: false,
    level: 1,
  },
  {
    id: "%2.edgenius%%2.1.createdge%",
    displayName: "2.1 Create a New Edge",
    rawData: { nodeId: "%2.edgenius%%2.1.createdge%" } as any,
    isExpandable: false,
    isExpanded: false,
    isVisible: true,
    isTitle: false,
    level: 2,
  },
  {
    id: "%2.edgenius%%2.2.editedge%",
    displayName: "2.2 Edit an Edge",
    rawData: { nodeId: "%2.edgenius%%2.2.editedge%" } as any,
    isExpandable: false,
    isExpanded: false,
    isVisible: true,
    isTitle: false,
    level: 2,
  },
  {
    id: "%2.edgenius%%2.3.downloadsoftware%",
    displayName: "2.3 Software Download",
    rawData: { nodeId: "%2.edgenius%%2.3.downloadsoftware%" } as any,
    isExpandable: false,
    isExpanded: false,
    isVisible: true,
    isTitle: false,
    level: 2,
  },
  {
    id: "%2.edgenius%%2.4.applicationadd%",
    displayName: "2.4 View and Add applications on an edge",
    rawData: { nodeId: "%2.edgenius%%2.4.applicationadd%" } as any,
    isExpandable: false,
    isExpanded: false,
    isVisible: true,
    isTitle: false,
    level: 2,
  }, {
    id: "%2.edgenius%%2.5.applicationdelete%",
    displayName: "2.5 Delete an application from the edge",
    rawData: { nodeId: "%2.edgenius%%2.5.applicationdelete%" } as any,
    isExpandable: false,
    isExpanded: false,
    isVisible: true,
    isTitle: false,
    level: 2,
  }, {
    id: "%2.edgenius%%2.6.exportconfig%",
    displayName: "2.6 Export the configuration",
    rawData: { nodeId: "%2.edgenius%%2.6.exportconfig%" } as any,
    isExpandable: false,
    isExpanded: false,
    isVisible: true,
    isTitle: false,
    level: 2,
  }, {
    id: "%2.edgenius%%2.7.systeminfo%",
    displayName: "2.7 View system info",
    rawData: { nodeId: "%2.edgenius%%2.7.systeminfo%" } as any,
    isExpandable: false,
    isExpanded: false,
    isVisible: true,
    isTitle: false,
    level: 2,
  },
  {
    id: "%2.edgenius%%2.8.systemupdate%",
    displayName: "2.8 Update the system modules",
    rawData: { nodeId: "%2.edgenius%%2.8.systemupdate%" } as any,
    isExpandable: false,
    isExpanded: false,
    isVisible: true,
    isTitle: false,
    level: 2,
  }, {
    id: "%2.edgenius%%2.9.appicationupdate%",
    displayName: "2.9 Update the application modules",
    rawData: { nodeId: "%2.edgenius%%2.9.appicationupdate%" } as any,
    isExpandable: false,
    isExpanded: false,
    isVisible: true,
    isTitle: false,
    level: 2,
  },
];
