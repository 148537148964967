import React, { useCallback, useEffect, useMemo } from "react";
import "./Edges.css";
import * as serversType from "./Type/TypeEdges";
import {
  AppHeader,
  AppMainContent,
  Button,
} from "@abb/abb-common-ux-react";
import WarningDialog from "../../Common/Components/WarningDialog/WarningDialog";
import CommonLoader from "../../Common/Components/CommonLoader/CommonLoader";
import * as edgeActions from "./Action/ActionEdges";
import { IState } from "../../Reducer";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import _ from "underscore";
import { envSettings } from "../../EnviornmentSettings/Settings";
import Table from "../../Common/Components/Table/Table";
import NewEdge from "./NewEdge/NewEdgeModal";
import { edgesTableHeader } from "./DataFormatter/DataFormatterEdges";
import { IEachRowObj } from "../../Common/Type/TypeCommon";
import { useHistory } from "react-router";

const urlprefix = envSettings.prefix === "/" ? "" : envSettings.prefix + "/";
const urlCopy = urlprefix;
let modifiedUrlPrefix = urlCopy.replace(/^\//, "");
modifiedUrlPrefix = modifiedUrlPrefix.replace(/\/\/$/, "/");
const Edges = (props: RouteComponentProps) => {
  const edgesState = useSelector((state: IState) => state.edgesState);
  console.log("edge stateeee-", edgesState)
  const commonState = useSelector((state: IState) => state.commonState);
  const history = useHistory();
  const dispatch = useDispatch();
  const followCursor = true,
    disabled = false,
    triggerOnClick = false;
  React.useEffect(() => {
    dispatch(edgeActions.getEdges());
  }, []);

  // useEffect(() => {
  //   const edgeName = edgesState.formattedEdgesData.map((edge) => {
  //     return edge.rawData.name;
  //   })
  //   console.log(edgeName);
  // }, [edgesState.formattedEdgesData]);

  const onConfirmDelete = () => {
    console.log("confirm delete");
    const rowEdgeData = edgesState.selectedEdge.rawData;
    const edge: serversType.IEdgedeObj = { id: rowEdgeData.objectId, name: rowEdgeData.name, 
      location: rowEdgeData.location, platformType:rowEdgeData.platformType };
    dispatch(edgeActions.closeDeleteUserModal())
    dispatch(edgeActions.deleteEdge(edge));
  };
  const handleNewUser = () => {
    dispatch(edgeActions.showNewEdgeModal());
  };
  const onActionClick = (
    actionType: "view" | "edit" | "delete",
    rowObj: IEachRowObj
  ) => {
    if (actionType === "delete") {
      dispatch(edgeActions.selectEdgeToDelete({ ...rowObj }));
    } else if (actionType === "edit") {
      dispatch(edgeActions.handleEditEdge({ ...rowObj }));
    }
  };
  const handleRowClick = (item: IEachRowObj) => {
   
      history.push({ pathname: `/edges`, search: `?edgeId=${item.id}` });
    // dispatch(edgeActions.handleShowApplicationsPage(true, { ...item }));

  };
  // console.log("check edges state", edgesState.showApplicationsPage);
  return (
    <React.Fragment>
     <CommonLoader isLoading={edgesState.isLoading} />
      {edgesState.isLoading ? <div className="loader-mask" /> : <div />}
      {/* <h1 className="edges-header-label">Edges</h1> */}
      <AppMainContent className="edges-wrap">
        {/* {!edgesState.showApplicationsPage ? ( */}
        <h1 className="edges-header-label">Edge Devices</h1>
        <div
          className={
            commonState.theme === "light"
              ? `edges-container light-theme`
              : `edges-container dark-theme`
          }
        >
          <div className="edges-header">
            <AppHeader className="edges-header-container">
              {/* <div className="edges-header-label">Edges</div> */}
              <div>
                <Button
                  text="New Disconnected Edge"
                  type="primary-blue"
                  sizeClass="small"
                  onClick={() => handleNewUser()}
                />
              </div>
            </AppHeader>
          </div>
          <div className="edges-body">
            <Table
              data={edgesState.formattedEdgesData}
              header={[...edgesTableHeader]}
              isViewActionEnabled={false}
              isDeleteActionEnabled={true}
              isEditActionEnabled={true}
              onActionClick={onActionClick}
              onRowClick={handleRowClick}
              noDataText={edgesState.isLoading ? "Loading..." : "No Edge Configured"}
            />
            {edgesState.showNewEdgeModal ? <NewEdge /> : <div />}
            {edgesState.showDeleteEdgeModal ? (
              <WarningDialog
                warningTitle={`Remove ${edgesState.selectedEdge.rawData?.name} ?`}
                warningMessage={<>{`Please confirm deletion of "${edgesState.selectedEdge.rawData?.name}" from 
                "${localStorage.getItem("tenant") !== null
                    ? localStorage.getItem("tenant")
                    : ""
                  }" permanently?`} <br /> {` Once removed it cannot be recovered.`}</>}
                confirmButtonText={"Remove"}
                cancelButtonText={"Cancel"}
                onCancel={() => dispatch(edgeActions.closeDeleteUserModal())}
                onConfirm={() => onConfirmDelete()}
              />
            ) : (
              <div />
            )}
          </div>
        </div>
        {/* { ) : (
           <div>
             <Applications />
           </div>
         )} */}
      </AppMainContent>
    </React.Fragment>
  );
};

export default withRouter(Edges);
