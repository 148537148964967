import * as edgeTypes from "../Type/TypeEdges";
import * as commonTypes from "../../../Common/Type/TypeCommon";
import { IEdgedeObj } from "../Type/TypeEdges";
export const getEdges = (): edgeTypes.IGetEdges => {
  return {
    type: "GET_EDGES",
  };
};

export const getEdge = (edgeId: string): edgeTypes.IGetEdge => {
  return {
    type: "GET_EDGE",
    edgeId
  };
};

export const createEdges = (edgeDetail: IEdgedeObj): edgeTypes.ICreateEdge => {
  return {
    type: "CREATE_EDGE",
    edgeDetail
  };
};
export const getAllApplications = (selectedEdgeId: string): edgeTypes.IGetAllApplications => {
  return {
    type: "GET_ALL_APPLICATIONS",
    selectedEdgeId
  };
};
export const exportAllApplications = (selectedEdge: commonTypes.IEachRowObj): edgeTypes.IExportApplications => {
  return {
    type: "EXPORT_ALL_APPLICATIONS",
    selectedEdge
  };
};
export const showNewEdgeModal = (): edgeTypes.IShowNewEdge => {
  return {
    type: "SHOW_NEW_EDGE_MODAL",
  };
};
export const hideNewEdgeModal = (): edgeTypes.IHideNewEdge => {
  return {
    type: "HIDE_NEW_EDGE_MODAL",
  };
};
export const handleNameChange = (
  nameText: string
): edgeTypes.IHandleNameChange => {
  return {
    type: "HANDLE_NAME_CHANGE",
    payload: nameText,
  };
};
export const handleEmailChange = (
  location: string
): edgeTypes.IHandleLocationChange => {
  return {
    type: "HANDLE_LOCATION_CHANGE",
    payload: location,
  };
};
export const selectEdgeToDelete = (
  selectedEdgeTobeDeleted: commonTypes.IEachRowObj
): edgeTypes.ISelectDeleteEdge => {
  return {
    type: "SELECT_DELETE_EDGE",
    selectedEdgeTobeDeleted,
  };
};

export const deleteEdge = (
  edgeDetail: IEdgedeObj
): edgeTypes.IDeleteEdge => {
  return {
    type: "DELETE_EDGE",
    edgeDetail
  };
};

export const closeDeleteUserModal = (): edgeTypes.ICloseDeleteEdgeModal => {
  return {
    type: "CLOSE_DELETE_EDGE_MODAL",
  };
};
export const handleEditEdge = (
  selectedEdge: commonTypes.IEachRowObj
): edgeTypes.IHandleEditEdge => {
  return {
    type: "HANDLE_EDIT_EDGE",
    selectedEdge,
  };
};
export const editEdge = (
  selectedEdge: IEdgedeObj
): edgeTypes.IEditEdge => {
  return {
    type: "EDIT_EDGE",
    selectedEdge,
  };
};
export const handleToggle = (
  showThisEdgeApps: boolean
): edgeTypes.IHandleToggle => {
  return {
    type: "HANDLE_TOGGLE",
    showThisEdgeApps,
  };
};
export const handleShowApplicationsPage = (
  selectedEdge: commonTypes.IEachRowObj
): edgeTypes.IHandleShowApplications => {
  return {
    type: "SET_SELECTED_EDGE",
    selectedEdge,
  };
};
export const handleAddRemoveUpdateApp = (
  addOrRemove: "add" | "remove" | "update",
  app: edgeTypes.IApplicationActionModel
): edgeTypes.IAddRemoveUpdateApp => {
  return {
    type: "ADD_REMOVE_UPDATE_APP",
    addRemoveOrUpdate: addOrRemove,
    app,
  };
};
export const handleShowEdgeInfo = (
  show: boolean
): edgeTypes.IHandleShowEdgeInfo => {
  return {
    type: "HANDLE_SHOW_EDGE_INFO",
    show,
  };
};

export const updateEdge = (
  edgeId: string
): edgeTypes.IUpdateEdge => {
  return {
    type: "UPDATE_EDGE",
    edgeId
  };
};
