import { envSettings } from "../EnviornmentSettings/Settings";

export const getEdges = !envSettings.isOfflineMode
  ? `${envSettings.webapiUrl}/Edge/getAllEdges`
  : "Fixtures/EdgesData.json";

export const getEdge = !envSettings.isOfflineMode
  ? `${envSettings.webapiUrl}/Edge/getEdge`
  : "Fixtures/Edge.json";

export const createEdge = `${envSettings.webapiUrl}/Edge/CreateEdge`;

export const getApplications = !envSettings.isOfflineMode
  ? `${envSettings.webapiUrl}/Application/getApplications`
  : "Fixtures/ApplicationData.json";

export const exportAllApplications = !envSettings.isOfflineMode
  ? `${envSettings.webapiUrl}/Edge/export`
  : "Fixtures/ApplicationData.json";

export const deleteEdge = !envSettings.isOfflineMode
  ? `${envSettings.webapiUrl}/Edge/deleteEdge`
  : "Fixtures/ApplicationData.json";

export const editEdge = !envSettings.isOfflineMode
  ? `${envSettings.webapiUrl}/Edge/editEdge`
  : "Fixtures/ApplicationData.json";

export const updateEdge = !envSettings.isOfflineMode
  ? `${envSettings.webapiUrl}/Edge/updateEdge`
  : "Fixtures/ApplicationData.json";

export const deleteApplication = !envSettings.isOfflineMode
  ? `${envSettings.webapiUrl}/Edge/deleteApplication`
  : "Fixtures/ApplicationData.json";

export const updateAppliation = !envSettings.isOfflineMode
  ? `${envSettings.webapiUrl}/Edge/updateApplication`
  : "Fixtures/ApplicationData.json";



export const addApplication = !envSettings.isOfflineMode
  ? `${envSettings.webapiUrl}/Edge/addApplication`
  : "Fixtures/ApplicationData.json";

export const getPreference = !envSettings.isOfflineMode
  ? `${envSettings.webapiUrl}/User/Login`
  : "Fixtures/preference.json";

export const updatePreference = `${envSettings.webapiUrl}/user/UpdateUserTheme`;

export const getAppLicenceInfo = !envSettings.isOfflineMode
  ? `${envSettings.webapiUrl}/Application/licenses`
  : "Fixtures/AppLicences.json";

export const getAppReleaseNoteHistory = !envSettings.isOfflineMode
  ? `${envSettings.webapiUrl}/Application/releaseNote`
  : "Fixtures/AppReleaseNotes.json";

export const getAppDetails = !envSettings.isOfflineMode
  ? `${envSettings.webapiUrl}/Application/`
  : "Fixtures/AppDetails.json";


export const edgeniusSoftwareList = `${envSettings.webapiUrl}/v1/edgenius/mode/disconnected/software`

export const  extendedCustomAppList = `${envSettings.webapiUrl}/v1/edgenius/mode/disconnected/extendedApplications`

export const edgeSoftwareGenerateLink = `${envSettings.webapiUrl}/v1/edgenius/mode/disconnected/software/downloadLink`

export const extendedCustomAppGenerateLink = `${envSettings.webapiUrl}/v1/edgenius/mode/disconnected/extendedApplications/downloadLink`