import {
  Icon,
  LoadingIndicator,
  Table,
  Checkbox,
} from "@abb/abb-common-ux-react";
import * as React from "react";
import * as tableInterfaces from "../../Type/TypeCommon";
import "./Table.css";

const TableView = (props: tableInterfaces.ITableProps) => {
  const getHeader = () => {
    const headerRow: JSX.Element[] = props.header.map((eachHeader, index) => {
      let headerCellClassName = "header-cell";
      if (eachHeader.className) {
        headerCellClassName += ` ${eachHeader.className}`;
      }
      if (props.isSortEnabled && eachHeader.isSortable) {
        headerCellClassName += ` sortable-header`;
      }
      if (eachHeader.id === props.sortedColumnId) {
        return (
          <th
            key={`heder${index}`}
            className={headerCellClassName}
            style={{ borderBottom: "4px solid #2E92FA" }}
            onClick={() =>
              props.isSortEnabled && eachHeader.isSortable && props.onSort
                ? props.onSort(eachHeader.id, props.sortDirection)
                : () => null
            }
          >
            <div className="header-content-wrap">
              <span>{eachHeader.displayName}</span>
              <span>
                {props.sortDirection && props.sortDirection !== "unsorted" ? (
                  <Icon
                    name={props.sortDirection === "asc" ? "abb/down" : "abb/up"}
                  />
                ) : (
                  <span />
                )}
              </span>
            </div>
          </th>
        );
      } else {
        return (
          <th
            key={`heder${index}`}
            className={headerCellClassName}
            onClick={() =>
              props.isSortEnabled && eachHeader.isSortable && props.onSort
                ? props.onSort(eachHeader.id, props.sortDirection)
                : () => null
            }
          >
            <div className="header-content-wrap">
              <span>{eachHeader.displayName}</span>
            </div>
          </th>
        );
      }
    });
    if (
      (props.isDeleteActionEnabled ||
        props.isEditActionEnabled ||
        props.isViewActionEnabled) &&
      props.data &&
      props.data.length > 0
    ) {
      headerRow.push(<th className="actions-header-column" />);
    }
    if (props.isCheckBoxEnabled) {
      if (props.showSelectAllOption) {
        headerRow.unshift(
          <td className="check-box-cell">
            <Checkbox
              sizeClass="small"
              value={props.isSelectedAll}
              disabled={props.isSelectAllDisabled}
              onChange={() =>
                props.handleSelectAll
                  ? props.handleSelectAll(props.isSelectedAll ? false : true)
                  : null
              }
            />
          </td>
        );
      } else {
        headerRow.unshift(<th className="checkBox-header-column" />);
      }
    }
    return (
      <thead>
        <tr>{headerRow}</tr>
      </thead>
    );
  };
  const getBody = () => {
    let eachRow: JSX.Element[] = [];
    const body: JSX.Element[] = [];
    props.data.map((item: tableInterfaces.IEachRowObj,index: number) => {
      eachRow = [];
      let rowClassCustom = "row-view";
      if (item.className) {
        rowClassCustom += ` ${rowClassCustom}`;
      }
      const groupedRowData: any = {};
      item.data.map((eachCellObj) => {
        groupedRowData[eachCellObj.id] = eachCellObj;
      });
      props.header.map((dataObj: tableInterfaces.ITableHeaderObj) => {
        const cellVal =
          groupedRowData && groupedRowData[dataObj["id"]]
            ? groupedRowData[dataObj["id"]]["formattedVal"]
            : "";
        const customClassName =
          groupedRowData && groupedRowData[dataObj["id"]]
            ? groupedRowData[dataObj["id"]]["className"]
            : undefined;
        let cellClassName = "cell";
        if (customClassName) {
          cellClassName += ` ${customClassName}`;
        }
        eachRow.push(
          <td className={cellClassName} title={cellVal}>
            {cellVal}
          </td>
        );
      });
      if (
        props.isDeleteActionEnabled ||
        props.isEditActionEnabled ||
        props.isViewActionEnabled
      ) {
        eachRow.push(
          <td className="action-cell" >
            <div className="action-icons-container" >
              {props.isViewActionEnabled ? (
                <div className="action-icon-wrap">
                  <Icon
                    name="abb/view"
                    className={
                      item.isViewActionEnabled
                        ? "table-action-icon"
                        : "table-action-icon action-disabled"
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      props.onActionClick
                        && props.onActionClick("view", { ...item })
                    }}
                  />
                </div>
              ) : null}
              {props.isEditActionEnabled ? (
                <div className="action-icon-wrap">
                  <Icon
                    name="abb/edit"
                    className={
                      item.isEditActionEnabled
                        ? "table-action-icon"
                        : "table-action-icon action-disabled"
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      props.onActionClick
                        && props.onActionClick("edit", { ...item })
                    }}
                  />
                </div>
              ) : null}
              {props.isDeleteActionEnabled ? (
                <div className="action-icon-wrap">
                  <Icon
                    name="abb/trash"
                    className={
                      item.isDeleteActionEnabled
                        ? "table-action-icon"
                        : "table-action-icon action-disabled"
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      props.onActionClick
                        && props.onActionClick("delete", { ...item })
                    }}
                  />
                </div>
              ) : null}
            </div>
          </td>
        );
      }
      if (props.isCheckBoxEnabled) {
        eachRow.unshift(
          <td className="check-box-cell">
            <Checkbox
              sizeClass="small"
              value={item.isSelected}
              disabled={!item.isSelectable}
              onChange={() =>
                props.onCheckBoxClick
                && props.onCheckBoxClick({ ...item })
              }
            />
          </td>
        );
      }
      body.push(
        <tr
          key={`edge-table-row-${item.id}-${index}`}
          className={item.isClickable ? "row-view click-class" : "row-view"}
          onClick={() =>
            item.isClickable && props.onRowClick
            && props.onRowClick(item)

          }
        >
          {eachRow}
        </tr>
      );
    });
    return <tbody>{body}</tbody>;
  };
  return (
    <React.Fragment>
      <Table sizeClass="large">
        {getHeader()}
        {props.isLoading ? (
          <div className="loading-container">
            <LoadingIndicator
              type="radial"
              sizeClass="medium"
              determinate={true}
            />
          </div>
        ) : props.data && props.data.length > 0 ? (
          getBody()
        ) : (
          <div />
        )}
      </Table>
      {props.data && props.data.length > 0 ? (
        <div />
      ) : (
        <Table>
          <tbody>
            <tr>
              <td className="no-data-container">{props.noDataText}</td>
            </tr>
          </tbody>
        </Table>
      )}
    </React.Fragment>
  );
};
export default TableView;
