import {
  put,
  takeEvery,
} from "redux-saga/effects";
import axios from "axios";
import * as edgeTypes from "../Type/TypeEdges";
import { envSettings } from "../../../EnviornmentSettings/Settings";
import * as apiEndPoints from "../../../Utils/ApiEndPoints";
import _ from "underscore";
import { formatEdgesData, formatApplicationData, formatEdgeData } from "../DataFormatter/DataFormatterEdges";
import { Downloader } from "../Applications/Export";

function* fetchEdges(action: edgeTypes.IGetEdges): any {
  // setCommonHeaders();
  try {
    const fetchedData = yield axios.get<any>(apiEndPoints.getEdges);
    const receivedData = !envSettings.isOfflineMode
      ? [...fetchedData.data]
      : [...fetchedData.data];
    const formattedEdgeData = formatEdgesData([...receivedData]);
    yield put({
      type: "RECEIVED_EDGES",
      edgeData: [...formattedEdgeData],
    });
  } catch (err) {
    console.error("error--", err);
    if (err.message === 'unhandled') {
      yield put({
        type: "SHOW_NOTIFICATION",
        notificationType: "alarm",
        message: "Falied to fetch the edges. Please try again later, or contact your system administrator if the problem persists.",
      });
    }
  }
}

function* fetchEdge(action: edgeTypes.IGetEdge): any {
  // setCommonHeaders();
  try {
    const fetchedData = yield axios.get<any>(apiEndPoints.getEdge, { params: { edgeId: action.edgeId } });
    const receivedData = { ...fetchedData.data };
    const formattedEdgeData = formatEdgeData(receivedData);
    yield put({
      type: "SET_SELECTED_EDGE",
      selectedEdge: formattedEdgeData,
    });
  } catch (err) {
    console.error("error--", err);
    if (err.message === 'unhandled') {
      yield put({
        type: "SHOW_NOTIFICATION",
        notificationType: "alarm",
        message: "Falied to fetch the edge data. Please try again later, or contact your system administrator if the problem persists.",
      });
    }
  }
}

function* fetchApplications(action: edgeTypes.IGetAllApplications): any {
  // setCommonHeaders();
  try {
    const fetchedData = yield axios.get<any>(apiEndPoints.getApplications,
      {
        params: {
          edgeId: action.selectedEdgeId,
        },
      });
    const receivedData = [...fetchedData.data];
    const formattedEdgeData = formatApplicationData([...receivedData]);
    // mock here
    yield put({
      type: "RECEIVED_ALL_APPLICATIONS",
      allApps: [...formattedEdgeData],
    });
  } catch (err) {
    console.error("error--", err);
    if (err.message === 'unhandled') {
      yield put({
        type: "SHOW_NOTIFICATION",
        notificationType: "alarm",
        message: "Failed to fetch applications. Please try again later, or contact your system administrator if the problem persists.",
      });
    }
  }
}

function* createEdge(action: edgeTypes.ICreateEdge): any {
  try {
    const fetchedData = yield axios.post<any>(
      `${apiEndPoints.createEdge}`,
      action.edgeDetail,
      {

      }
    );
    yield put({
      type: "HIDE_NEW_EDGE_MODAL"
    })
    yield put({
      type: "GET_EDGES"
    });
    yield put({
      type: "SHOW_NOTIFICATION",
      notificationType: "success",
      message: "Edge created successfuly.",
    });

  } catch (err) {
    yield put({
      type: "HIDE_NEW_EDGE_MODAL"
    })
    console.error("error--", err);
    if (err.message === 'unhandled') {
      yield put({
        type: "SHOW_NOTIFICATION",
        notificationType: "alarm",
        message: "Failed to create edge. Please try again later, or contact your system administrator if the problem persists.",
      });
    }
  }
}

function* exportApplication(action: edgeTypes.IExportApplications): any {
  try {
    const fetchedData = yield axios.get<any>(apiEndPoints.exportAllApplications,
      {
        params: {
          edgeId: action.selectedEdge.id,
        },
      });
    Downloader.exportFile(fetchedData.data, action.selectedEdge.rawData?.name);
    yield put({
      type: "SHOW_NOTIFICATION",
      notificationType: "success",
      message: "Export completed.",
    });
  } catch (err) {
    console.error("error--", err);
    if (err.message === 'unhandled') {
      yield put({
        type: "SHOW_NOTIFICATION",
        notificationType: "alarm",
        message: "Failed to export. Please try again later, or contact your system administrator if the problem persists.",
      });
    }
  }
}

function* addRemoveOrUpdateApplication(action: edgeTypes.IAddRemoveUpdateApp): any {
  try {
    switch (action.addRemoveOrUpdate) {
      case "add": {
        const fetchedData = yield axios.post<any>(
          `${apiEndPoints.addApplication}`,
          action.app,
          {}
        );
        yield put({
          type: "SHOW_NOTIFICATION",
          notificationType: "success",
          message: "Application added successfully.",
        });
        break;
      }
      case "remove": {
        const fetchedData = yield axios.delete<any>(
          `${apiEndPoints.deleteApplication}`,
          {
            data: action.app
          });
        yield put({
          type: "SHOW_NOTIFICATION",
          notificationType: "success",
          message: "Application removed successfully.",
        });
        break;
      }
      case "update": {
        const fetchedData = yield axios.put<any>(
          `${apiEndPoints.updateAppliation}`,
          action.app
        );
        yield put({
          type: "SHOW_NOTIFICATION",
          notificationType: "success",
          message: "Application updated successfully.",
        });
        break;
      }
      default: {
        return;
      }
    }

    yield put({
      type: "GET_ALL_APPLICATIONS",
      selectedEdgeId: action.app.edgeId
    })
    yield put({
      type: "GET_EDGE",
      edgeId: action.app.edgeId
    })

  } catch (err) {
    console.error("error--", err);
    if (err.message === 'unhandled') {
      let errorMessage = "Failed to update application. Please try again later, or contact your system administrator if the problem persists.";
      if (action.addRemoveOrUpdate === "add") {
        errorMessage = "Failed to add application. Please try again later, or contact your system administrator if the problem persists.";
      } else if (action.addRemoveOrUpdate === "remove") {
        errorMessage = "Failed to remove application. Please try again later, or contact your system administrator if the problem persists.";
      }
      yield put({
        type: "SHOW_NOTIFICATION",
        notificationType: "alarm",
        message: errorMessage,
      });
    }
  }

}

function* editEdge(action: edgeTypes.IEditEdge): any {
  try {
    const fetchedData = yield axios.put<any>(
      `${apiEndPoints.editEdge}`,
      { name: action.selectedEdge.name, edgeId: action.selectedEdge.id, location: action.selectedEdge.location, description: "",
      platformType: action.selectedEdge.platformType },
    );

    yield put({
      type: "HIDE_NEW_EDGE_MODAL"
    });

    yield put({
      type: "GET_EDGES"
    });
    yield put({
      type: "SHOW_NOTIFICATION",
      notificationType: "success",
      message: "Updated edge details successfully.",
    });
  } catch (err) {
    console.error("error--", err);
    if (err.message === 'unhandled') {
      yield put({
        type: "SHOW_NOTIFICATION",
        notificationType: "alarm",
        message: "Failed to update the edge. Please try again later, or contact your system administrator if the problem persists.",
      });
    }
  }
}

function* deleteEdge(action: edgeTypes.IDeleteEdge): any {
  try {

    const fetchedData = yield axios.delete<any>(
      `${apiEndPoints.deleteEdge}`,
      {
        params: { edgeId: action.edgeDetail.id, platformType: action.edgeDetail.platformType }
      }
    );

    yield put({
      type: "GET_EDGES"
    });
    yield put({
      type: "SHOW_NOTIFICATION",
      notificationType: "success",
      message: "Edge deleted successsfully.",
    });
  } catch (err) {
    console.error("error--", err);
    if (err.message === 'unhandled') {
      yield put({
        type: "SHOW_NOTIFICATION",
        notificationType: "alarm",
        message: "Failed to delete the edge. Please try again later, or contact your system administrator if the problem persists.",
      });
    }
  }
}

function* updateEdge(action: edgeTypes.IUpdateEdge): any {
  try {
    const fetchedData = yield axios.put<any>(
      `${apiEndPoints.updateEdge}`, {},
      { params: { edgeId: action.edgeId } },
    );
    yield put({
      type: "GET_EDGE",
      edgeId: action.edgeId
    });
    yield put({
      type: "GET_ALL_APPLICATIONS",
      selectedEdgeId: action.edgeId
    });
    yield put({
      type: "SHOW_NOTIFICATION",
      notificationType: "success",
      message: "Updated successfully.",
    });
  } catch (err) {
    console.error("error--", err);
    if (err.message === 'unhandled') {
      yield put({
        type: "SHOW_NOTIFICATION",
        notificationType: "alarm",
        message: "Failed to update edge. Please try again later, or contact your system administrator if the problem persists.",
      });
    }
  }
}

export default function* actionWatcherEdges() {
  yield takeEvery("GET_EDGES", fetchEdges);
  yield takeEvery("GET_EDGE", fetchEdge);
  yield takeEvery("GET_ALL_APPLICATIONS", fetchApplications);
  yield takeEvery("CREATE_EDGE", createEdge);
  yield takeEvery("EXPORT_ALL_APPLICATIONS", exportApplication);
  yield takeEvery("ADD_REMOVE_UPDATE_APP", addRemoveOrUpdateApplication);
  yield takeEvery("EDIT_EDGE", editEdge);
  yield takeEvery("DELETE_EDGE", deleteEdge);
  yield takeEvery("UPDATE_EDGE", updateEdge);
}
