import React from "react";
import "./Header.css";
import {
  AppTopNavi,
  AppHeader,
  AppTopNaviItem,
  AppTopNaviDivider,
} from "@abb/abb-common-ux-react";
import { UserMenu, SideMenu } from "@abb/abb.ia.components.react";
import License from "../License/License";
import ReleaseNotes from "../ReleaseNotes/ReleaseNotes";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { envSettings } from "../../../EnviornmentSettings/Settings";
import jwt_decode from "jwt-decode";
import { commonAuthHelper } from "../../../App";
import { useDispatch, useSelector } from "react-redux";
import * as commonActions from "../../Action/ActionCommon";
import { IState } from "../../../Reducer";

const urlprefix = envSettings.prefix === "/" ? "" : envSettings.prefix + "/";
const Header = (props: RouteComponentProps) => {
  return (
    <AppHeader>
      <AppTopNavi className="header-left"></AppTopNavi>
      <HeaderRightPannel {...props} />
    </AppHeader>
  );
};
const HeaderRightPannel = (props: RouteComponentProps) => {
  const commonState = useSelector((state: IState) => state.commonState);
  const dispatch = useDispatch();
  const [showUserProfile, toggleUserProfile] = React.useState(false);
  const [showLicenseDialog, setShwoLicenseDialog] = React.useState(false);
  const [showReleaseNotes, setShowReleaseNotes] = React.useState(false);
  const [showSettings, setShowSettings] = React.useState(false);
  const userLogout = () => {
    if (envSettings.connectivityMode === "connected") {
      localStorage.clear();
      sessionStorage.clear();
      document.location.replace("/");
    }
    commonAuthHelper.logOut();
  };
  const toggleProfile = () => {
    toggleUserProfile(!showUserProfile);
  };
  const closeUserProfile = () => {
    toggleUserProfile(false);
  };
  const onClickLicense = () => {
    toggleUserProfile(false);
    setShwoLicenseDialog(true);
  };
  const onClickReleaseNotes = () => {
    setShowReleaseNotes(true);
    toggleUserProfile(false);
  };
  const showSettingsMenu = () => {
    setShowSettings(true);
    toggleUserProfile(false);
  };
  const hideSettingsMenu = () => {
    setShowSettings(false);
  };
  const token = commonAuthHelper.getAccessToken();
  const decodedToken = token && token !== null ? jwt_decode(token) : "";
  const decodedTokenStr = JSON.stringify(decodedToken);
  const authDataToken = JSON.parse(decodedTokenStr);
  let userNameToBeDisplayed: string = "";
  console.log("check token 111", authDataToken);
  userNameToBeDisplayed =
    authDataToken && authDataToken.name ? authDataToken.name : "";
  const userRoles: string[] =
    authDataToken && authDataToken.roles ? authDataToken.roles : [];
  return (
    <AppTopNavi className="app-top-navi-right">
      {props.location &&
        props.location.pathname &&
        props.location.pathname.indexOf("Help") >= 0 ? (
        <></>
      ) : (
        <>
          <HelpIcon />
          <AppTopNaviDivider />
        </>
      )}

      <UserMenu
        tenant={`${localStorage.getItem("tenant") !== null
          ? localStorage.getItem("tenant")
          : ""
          }`}
        username={`${userNameToBeDisplayed}`}
        showSettings={false}
        // onSettings={showSettingsMenu}
        roles={userRoles}
        instance={""}
        isOpen={showUserProfile}
        showSwitchTenant={false}
        trigger={
          <AppTopNaviItem
            id="UserMenuItem"
            icon="abb/user-in-circle"
            active={showUserProfile}
            onClick={() => toggleProfile()}
          />
        }
        position="bottom right"
        onRequestClose={() => closeUserProfile()}
        onSignOut={() => {
          userLogout();
        }}
        onLicensing={() => {
          onClickLicense();
        }}
        onReleaseNotes={() => {
          onClickReleaseNotes();
        }}
        onPrivacyPolicy={() => {
          window.open("https://new.abb.com/privacy-notice/customer", "_blank");
        }}
      />
      <SideMenu
        isOpen={showSettings}
        position="right"
        onRequestClose={hideSettingsMenu}
        header="Settings"
        className={`${commonState.theme === "light"
          ? "light-theme-settings"
          : "dark-theme-settings"
          }`}
      >
        <div className={`settings-menu-view`}>
          <div className="theme-settings-header">Theme</div>
          <div className="theme-settings-view">
            <div
              className={`each-theme-view light-settings-view ${commonState.theme === "light"
                ? "selected-theme-view"
                : "un-selected-light"
                }`}
              onClick={
                commonState.theme === "light"
                  ? () => null
                  : () => dispatch(commonActions.changePreference("light"))
              }
            >
              <div className="each-theme-image">
                <img src="images/lighttheme.png" width="150" height="100" />
              </div>
              <div className="each-theme-label">Light</div>
            </div>
            <div
              className={`each-theme-view dark-settings-view ${commonState.theme === "dark"
                ? "selected-theme-view"
                : "un-selected-dark"
                }`}
              onClick={
                commonState.theme === "dark"
                  ? () => null
                  : () => dispatch(commonActions.changePreference("dark"))
              }
            >
              <div className="each-theme-image">
                <img src="images/darktheme.png" width="150" height="100" />
              </div>
              <div className="each-theme-label">Dark</div>
            </div>
          </div>
        </div>
      </SideMenu>
      {showLicenseDialog ? (
        <License
          showLicense={showLicenseDialog}
          onCloseLicense={() => setShwoLicenseDialog(false)}
        />
      ) : (
        <div />
      )}

      {showReleaseNotes ? (
        <ReleaseNotes
          showReleaseNotes={showReleaseNotes}
          onCloseReleaseNotes={() => setShowReleaseNotes(false)}
        />
      ) : (
        <div />
      )}
    </AppTopNavi>
  );
};
const HelpIcon = () => {
  const getHelp = () => {
    const urlCopy = urlprefix;
    let modifiedUrlPrefix = urlCopy.replace(/^\//, "");
    modifiedUrlPrefix = modifiedUrlPrefix.replace(/\/\/$/, "/");
    const helpLink: any = `${window.location.origin}/${modifiedUrlPrefix}Help`;
    window.open(helpLink);
  };
  return (
    <AppTopNaviItem
      className="online-doc"
      icon="abb/help_circle1"
      onClick={getHelp}
    />
  );
};


export default withRouter(Header);
