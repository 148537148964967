import { IAppInfoActions } from "../Type/AppInfoActionTypes";
import _ from "underscore";
import { IAppInfoState } from "../Type/AppInfoTypes";

const TOTAL_API_COUNT = 1;
const initialState: IAppInfoState = {
    releaseHistory: [],
    licenceText: "",
    appDetails: {
        modules: [],
        majorVersion: '',
        name: '',
        description: '',
        tags: []
    },
    isLoading: false,
    progressCount: 0
};

export default function (
    state: IAppInfoState = initialState,
    action: IAppInfoActions
): IAppInfoState {
    switch (action.type) {
        case "FETCH_ALL_APPLICATION_INFO":
            return {
                ...state,
                isLoading: true,
                progressCount: 0
            };
        case "RECEIVED_APPLICATION_DETAILS":
            {
                const progressCount = state.progressCount + 1;
                return {
                    ...state,
                    progressCount,
                    appDetails: action.payload,
                    isLoading: (progressCount !== TOTAL_API_COUNT)
                };
            }
        case "RECEIVED_APP_LICENCE_INFO":
            {
                const progressCount = state.progressCount + 1;
                return {
                    ...state,
                    progressCount,
                    licenceText: action.payload,
                    isLoading: (progressCount !== TOTAL_API_COUNT)
                };
            }
        case "RECEIVED_RELEASENOTE_INFO":
            {
                const progressCount = state.progressCount + 1;
                return {
                    ...state,
                    progressCount,
                    releaseHistory: action.payload?.reverse(),
                    isLoading: (progressCount !== TOTAL_API_COUNT)
                }
            };
        case "FAILED_TO_FETCH":
            {
                const progressCount = state.progressCount + 1;
                return {
                    ...state,
                    progressCount,
                    isLoading: (progressCount !== TOTAL_API_COUNT)
                }
            };
        default:
            return state;
    }
}
