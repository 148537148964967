import edgesState, {
  IEdgesDetailsStateType,
} from "../Views/Edges/Reducer/ReducerEdges";

import { combineReducers } from "redux";
import commonState, { ICommonStateType } from "../Common/Reducer/ReducerCommon";
import { IAppInfoState } from "../Views/ApplicationInfo/Type/AppInfoTypes";
import appInfoState from "../Views/ApplicationInfo/Reducer/ReducerAppInfo";

export interface IState {
  edgesState: IEdgesDetailsStateType;
  commonState: ICommonStateType;
  appInfoState: IAppInfoState;
}

const allReducers = combineReducers({
  edgesState,
  commonState,
  appInfoState
});

export type AppState = ReturnType<typeof allReducers>;
export default allReducers;
