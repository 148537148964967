import React from "react";
import Edges from "./Edges/Edges";
import UnAuthorizedView from "../Common/Components/UnAuthorizedView/UnAuthorizedView";
import Documentation from "../Common/Components/Documentation/Documentation";
import { PrivateRoute } from "./PrivateRoutes";
import { useSelector } from "react-redux";
import { IState } from "../Reducer";
import { Switch, Route, withRouter } from "react-router-dom";
import Applications from "./Edges/Applications/Applications";
import ApplicationInfo from "./ApplicationInfo/ApplicationInfo";


const AppRoutes = () => {
  const commonState = useSelector((state: IState) => state.commonState);
  return (
    <Switch>
      {/* <PrivateRoute
        exact
        path="/"
        component={!commonState.showUnAuthorized ? Ymls : UnAuthorizedView}
      /> */}
      <Route
        exact
        path="/"
        component={!commonState.showUnAuthorized ? Edges : UnAuthorizedView}
      />
      <Route
        exact
        path="/edges"
        component={!commonState.showUnAuthorized ? Applications : UnAuthorizedView}
      />
      <Route
        exact
        path="/applications"
        component={!commonState.showUnAuthorized ? ApplicationInfo : UnAuthorizedView}
      />
      <Route
        path="/help"
        component={
          !commonState.showUnAuthorized ? Documentation : UnAuthorizedView
        }
      />
    </Switch>
  );
};
export default withRouter(AppRoutes);
