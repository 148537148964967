import React from "react";
import "./WarningDialog.css";
import { Dialog, Button } from "@abb/abb-common-ux-react";
import { withRouter, RouteComponentProps } from "react-router-dom";

interface IWarningDialogProps extends RouteComponentProps {
  warningTitle:string;
  warningMessage: string | JSX.Element;
  confirmButtonText: string;
  cancelButtonText: string;
  onCancel: () => void;
  onConfirm: () => void;
}

const WarningDialog = (props: IWarningDialogProps) => {
  return (
    <div className="warning-dialog-wrap">
      <Dialog
        showCloseButton={true}
        closeOnEscape={true}
        closeOnLostFocus={false}
        dimBackground={true}
        isOpen={true}
        onClose={() => props.onCancel()}
        title={props.warningTitle}
        className="warning-dialog-modal"
      >
        <div>{props.warningMessage}</div>
        <div className="submit-cancel-warning-wrap">
          <Button
            sizeClass="medium"
            type="normal"
            className="btn-cancel-warning-modal"
            onClick={() => props.onCancel()}
            text={props.cancelButtonText}
          />
          <Button
            sizeClass="medium"
            type="primary-blue"
            className="btn-confirm-warning-modal"
            onClick={() => props.onConfirm()}
            text={props.confirmButtonText}
          />
        </div>
      </Dialog>
    </div>
  );
};

export default withRouter(WarningDialog);
