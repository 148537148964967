import * as edgeTypes from "../Type/TypeEdges";
import * as commonTypes from "../../../Common/Type/TypeCommon";
import _ from "underscore";

export interface IEdgesDetailsStateType {
  formattedEdgesData: commonTypes.IEachRowObj[];
  isLoading: boolean;
  selectedEdgeName: string;
  selectedEdgeLocation: string;
  setEdgeplatform: string;
  selectedEdge: commonTypes.IEachRowObj;
  showNewEdgeModal: boolean;
  showDeleteEdgeModal: boolean;
  isEditEdge: boolean;
  showThisEdgeApps: boolean;
  allApplications: edgeTypes.IApplicationObj[];
  thisDeviceApps: edgeTypes.IApplicationObj[];
  availableApps: edgeTypes.IApplicationObj[];
  showApplicationsPage: boolean;
  showEdgeInfo: boolean;
}

const initialState: IEdgesDetailsStateType = {
  formattedEdgesData: [],
  isLoading: false,
  selectedEdgeName: "",
  selectedEdgeLocation: "",
  setEdgeplatform: "",
  selectedEdge: {} as commonTypes.IEachRowObj,
  showNewEdgeModal: false,
  showDeleteEdgeModal: false,
  isEditEdge: false,
  showThisEdgeApps: true,
  allApplications: [],
  showApplicationsPage: false,
  thisDeviceApps: [],
  availableApps: [],
  showEdgeInfo: false,
};

export default function (
  state: IEdgesDetailsStateType = initialState,
  action: edgeTypes.IEdgeActions
): IEdgesDetailsStateType {
  switch (action.type) {
    case "GET_EDGES":
      return {
        ...state,
        isLoading: true,
      };
    case "RECEIVED_EDGES":
      return {
        ...state,
        isLoading: false,
        formattedEdgesData: [...action.edgeData],
      };
    case "CREATE_EDGE":
      return {
        ...state,
        isLoading: true
      };
    case "SHOW_NEW_EDGE_MODAL":
      return {
        ...state,
        showNewEdgeModal: true,
      };
    case "HIDE_NEW_EDGE_MODAL":
      return {
        ...state,
        selectedEdge: {} as commonTypes.IEachRowObj,
        showNewEdgeModal: false,
        selectedEdgeName: "",
        selectedEdgeLocation: "",
        isEditEdge: false,
      };
    case "HANDLE_NAME_CHANGE":
      return {
        ...state,
        selectedEdgeName: action.payload,
      };
    case "HANDLE_LOCATION_CHANGE":
      return {
        ...state,
        selectedEdgeLocation: action.payload,
      };
    case "SELECT_DELETE_EDGE":
      return {
        ...state,
        selectedEdge: { ...action.selectedEdgeTobeDeleted },
        showDeleteEdgeModal: true,
      };
    case "DELETE_EDGE":
      return {
        ...state,
        isLoading: true
      };
    case "CLOSE_DELETE_EDGE_MODAL":
      return {
        ...state,
        showDeleteEdgeModal: false,
        selectedEdge: {} as commonTypes.IEachRowObj,
      };
    case "HANDLE_EDIT_EDGE":
      return {
        ...state,
        isEditEdge: true,
        showNewEdgeModal: true,
        selectedEdge: action.selectedEdge,
        selectedEdgeName: action.selectedEdge.rawData.name
          ? action.selectedEdge.rawData.name
          : "",
        selectedEdgeLocation: action.selectedEdge.rawData.location
          ? action.selectedEdge.rawData.location
          : "",
      };
    case "EDIT_EDGE":
      return {
        ...state,
        isLoading: true
      };
    case "HANDLE_TOGGLE":
      return {
        ...state,
        showThisEdgeApps: action.showThisEdgeApps,
      };
    case "GET_ALL_APPLICATIONS":
      return {
        ...state,
        isLoading: true,
      };
    case "RECEIVED_ALL_APPLICATIONS":
      const thisDeviceApps = [...action.allApps].filter(
        (item) => item.isInstalled
      );
      const availableApps = [...action.allApps].filter(
        (item) => !item.isInstalled
      );
      return {
        ...state,
        isLoading: false,
        allApplications: [...action.allApps],
        thisDeviceApps,
        availableApps,
      };
    case "SET_SELECTED_EDGE":
      return {
        ...state,
        selectedEdge: { ...action.selectedEdge },
      };
    case "ADD_REMOVE_UPDATE_APP":
      return {
        ...state,
        isLoading: true,
      };
    case "HANDLE_SHOW_EDGE_INFO":
      return {
        ...state,
        showEdgeInfo: action.show,
      };
    case "ON_FAIL_SERVERS":
      return {
        ...state,
        isLoading: false
      };
      case "UPDATE_EDGE":
        return {
          ...state,
          isLoading: true
        };
    default:
      return state;
  }
}
