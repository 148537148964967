import {
  AppMainContent,
  Button,
  Checkbox,
  Dialog,
  Tooltip,
  WithTooltip,
} from "@abb/abb-common-ux-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CommonLoader from "../../../Common/Components/CommonLoader/CommonLoader";
import { IState } from "../../../Reducer";
import "./Applications.css";
import * as edgeActions from "../Action/ActionEdges";
import { IEachRowObj } from "../../../Common/Type/TypeCommon";
import { IApplicationActionModel } from "../Type/TypeEdges";
import { EdgeInfo } from "./EdgeInfo";
import { useHistory, useLocation, useParams, withRouter } from "react-router";
import { IAppDetails } from "../../ApplicationInfo/Type/AppInfoTypes";
import { setAppDetails } from "../../ApplicationInfo/Action/ActionAppInfo";
import WarningDialog from "../../../Common/Components/WarningDialog/WarningDialog";
import { envSettings } from "../../../EnviornmentSettings/Settings";
import axios from "axios";
import * as apiEndPoints from "../../../Utils/ApiEndPoints";

import { commonAuthHelper } from "../../../App";
import jwt_decode from "jwt-decode";

interface softwareDetails {
  key: string;
  lastModified: Date;
  etag: number;
  size: number;
}
const Applications = () => {
  
  const edgesState = useSelector((state: IState) => state.edgesState);
  const commonState = useSelector((state: IState) => state.commonState);

  const [confiurationView, setConfigurationView] = useState(true);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const [disconnectedEdgeSoftwares, setdisconnectedEdgeSoftwares] = useState(
    []
  );

  const [customExtendedSoftwares, setcustomExtendedSoftwares] = useState([]);

  const [isValidUser, setIsValidUser] = useState(false);
  const [downloadLink, setDownloadLink] = useState<any>("");


  const history = useHistory();
  const dispatch = useDispatch();
  const edgeId = new URLSearchParams(useLocation().search).get("edgeId");
  // const { edgeId } = useParams() as any;
  useEffect(() => {
    if (edgeId) {
      dispatch(edgeActions.getEdge(edgeId));
      dispatch(edgeActions.getAllApplications(edgeId));
    }


    const token = commonAuthHelper.getAccessToken();
    const decodedToken = token && token !== null ? jwt_decode(token) : "";
    const decodedTokenStr = JSON.stringify(decodedToken);
    const authDataToken = JSON.parse(decodedTokenStr);
    const userRoles: string[] =
      authDataToken && authDataToken.roles ? authDataToken.roles : [];

    var isRolePresent = userRoles.filter((role) => role === "SoftwareDownload");

    if (isRolePresent.length != 0) {
      fetchSoftwaresData();
      fettchCustomAppData();
      setIsValidUser(true);
    } else {
      setIsValidUser(false);
      setConfigurationView(true);
    }
  }, []);

  const fetchSoftwaresData = async () => {
    await axios
      .get(`${apiEndPoints.edgeniusSoftwareList}`)

      // .then((res) => res.json())
      .then((response) => {
        setdisconnectedEdgeSoftwares(response.data);
      });

  };

  const fettchCustomAppData = async () => {
    await axios
      .get(`${apiEndPoints.extendedCustomAppList}`)

      // .then((res) => res.json())
      .then((response) => {
        setcustomExtendedSoftwares(response.data);
      });

  };

  const getDownloadLinkforEdgenusSoftware = (filename: string) => {
    setDownloadLink("");
    setShowConfirmation(true);

    axios
      .post(`${apiEndPoints.edgeSoftwareGenerateLink}`, {
        name: filename,
      })
      // .then((res) => res.json())
      .then((response) => {
        setDownloadLink(response.data.downloadableLink);
      });
  };

  const getDownloadLinkforExtendedApps = (filename: string) => {
    setDownloadLink("");
    setShowConfirmation(true);

    axios
      .post(
        `${apiEndPoints.extendedCustomAppGenerateLink}`,

        {
          name: filename,
        }
      )

      .then((response) => {
        setDownloadLink(response.data.downloadableLink);
      });
  };

  function formatBytes(a: number, b = 2) {

    if (!+a) return "0 Bytes";
    const c = 0 > b ? 0 : b,
      d = Math.floor(Math.log(a) / Math.log(1024));
    return `${parseFloat((a / Math.pow(1024, d)).toFixed(c))} ${
      ["Bytes", "KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"][d]
    }`;
  }

  useEffect(() => {
    console.log("selected edge-", edgesState.selectedEdge);
  }, [edgesState.selectedEdge]);
  const getApplicationList = (type: "thisEdge" | "available") => {
    // const DataToBeProccessed =
    //   type === "installed"
    //     ? [...edgesState.allApplications].filter((item) => item.isInstalled)
    //     : [...edgesState.allApplications].filter((item) => !item.isInstalled);
    const DataToBeProccessed =
      type === "thisEdge"
        ? [...edgesState.thisDeviceApps]
        : [...edgesState.availableApps];
    const applicationListView: JSX.Element[] = [...DataToBeProccessed].map(
      (item) => {
        return (
          <div
            className="each-list-wrap"
            onClick={(e) => {
              e.stopPropagation();
              const appDetails: IAppDetails = {
                description: item.rawObj?.description,
                majorVersion: item.rawObj?.version,
                modules: undefined,
                name: item.name,
                tags: [],
              };
              dispatch(setAppDetails(appDetails));
              history.push({
                pathname: `/applications`,
                search: `?appId=${item.id}`,
              });
            }}
          >
            <div className="each-list-content">
              <div className="each-list-details">
                <h3 className="application-name">{item.name}</h3>

                {/* {item.isInstalled ? ( */}
                {/* {item.isInstalled && item.isUpdateAvailable ? (
                  <h4 className="application-state">Update Available</h4>
                )
                  : ( */}
                <h4 className="application-state">{`Version : ${
                  item.isInstalled
                    ? item.rawObj?.installedVersion
                    : item.rawObj?.version
                }`}</h4>
                {/* )} */}
                {/*)
                  : item.isUpdateAvailable ? (
                  <h4 className="application-state">Update Available</h4>
                ) : (
                  <></>
                )} */}
              </div>
              <div className="each-list-icons">
                <div className="each-list-icons-container">
                  {item.isInstalled && item.isUpdateAvailable && (
                    <WithTooltip>
                      <Button
                        icon="abb/refresh"
                        className="save-icon"
                        sizeClass="large"
                        type="discreet-black"
                        onClick={(e) => {
                          e.stopPropagation();
                          let edgeDetail: IApplicationActionModel = {
                            edgeId: edgesState.selectedEdge.id,
                            applictionModelId: item.model,
                            applictionTypeId: item.type,
                          };
                          dispatch(
                            edgeActions.handleAddRemoveUpdateApp(
                              "update",
                              edgeDetail
                            )
                          );
                        }}
                      />
                      <Tooltip>
                        <p>Update Available</p>
                        <p>Latest Version {item.rawObj?.version}</p>
                      </Tooltip>
                    </WithTooltip>
                  )}
                  {!item.isInstalled && type === "available" && (
                    <WithTooltip>
                      <Button
                        icon="abb/plus"
                        className="save-icon"
                        onClick={(e) => {
                          e.stopPropagation();
                          let edgeDetail: IApplicationActionModel = {
                            edgeId: edgesState.selectedEdge.id,
                            applictionModelId: item.model,
                            applictionTypeId: item.type,
                          };
                          dispatch(
                            edgeActions.handleAddRemoveUpdateApp(
                              "add",
                              edgeDetail
                            )
                          );
                        }}
                        sizeClass="large"
                        type="discreet-black"
                      />
                      <Tooltip>
                        <p>Add Application</p>
                      </Tooltip>
                    </WithTooltip>
                  )}
                  {!item.isInstalled && type === "thisEdge" && (
                    <WithTooltip>
                      <Button
                        icon="abb/minus"
                        className="save-icon"
                        onClick={(e) => {
                          e.stopPropagation();
                          let edgeDetail: IApplicationActionModel = {
                            edgeId: edgesState.selectedEdge.id,
                            applictionModelId: item.model,
                            applictionTypeId: item.type,
                          };
                          dispatch(
                            edgeActions.handleAddRemoveUpdateApp(
                              "remove",
                              edgeDetail
                            )
                          );
                        }}
                        sizeClass="large"
                        type="discreet-black"
                      />
                      <Tooltip>
                        <p>Remove Application</p>
                      </Tooltip>
                    </WithTooltip>
                  )}
                  {item.isInstalled && type === "thisEdge" && (
                    <WithTooltip>
                      <Button
                        icon="abb/trash"
                        className="save-icon"
                        onClick={(e) => {
                          e.stopPropagation();
                          let edgeDetail: IApplicationActionModel = {
                            edgeId: edgesState.selectedEdge.id,
                            applictionModelId: item.model,
                            applictionTypeId: item.type,
                          };
                          dispatch(
                            edgeActions.handleAddRemoveUpdateApp(
                              "remove",
                              edgeDetail
                            )
                          );
                        }}
                        sizeClass="large"
                        type="discreet-black"
                      />
                      <Tooltip>
                        <p>Remove Application</p>
                      </Tooltip>
                    </WithTooltip>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      }
    );
    return (
      <div className="application-section-container">
        <div className="application-section-content">{applicationListView}</div>
      </div>
    );
  };


  const DownloadContainer = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          rowGap: "20px",
          width: "1000px",
        }}
      >
        <span>
          Download link created. Copy this link before closing this dialog and
          use it via browser for download. This link is valid only for next 24
          hours.{" "}
        </span>
        <span className="DownloadLinkContainer">
          {downloadLink == "" ? "Loading Link..." : downloadLink}
        </span>
      </div>
    );
  };


  return (
    <React.Fragment>
      <CommonLoader isLoading={edgesState.isLoading} />
      {edgesState.isLoading ? <div className="loader-mask" /> : <div />}
      <AppMainContent className="edges-wrap">
        <div className="browse-top-left">
          <div
            className="bread-crumb-cell bread-crumb-server"
            onClick={() => {
              //   browseActions.resetBrowseState();
              history.push("/");
              // dispatch(
              //   edgeActions.handleShowApplicationsPage(false, {} as IEachRowObj)
              // );
            }}
          >{`Edges`}</div>
          <div className="bread-crumb-devider">/</div>
          <div className="bread-crumb-cell">
            {edgesState.selectedEdge.rawData
              ? edgesState.selectedEdge.rawData.name
              : ""}
            <span className="edge-platform-name">
              {edgesState.selectedEdge.rawData
                ? ` - ${edgesState.selectedEdge.additionalInfo.platformType}`
                : ""}
            </span>
            <span>
              {` / ${
                confiurationView == true ? "Configuration" : "Software Download"
              }`}
            </span>
          </div>
            {confiurationView === true &&
          <React.Fragment>
          <div className="information-edge">
            <Button
              icon="abb/information-circle-1"
              className="edge-info-icon"
              //   onClick={(e) => {
              //     e.stopPropagation();
              //     dispatch(edgeActions.handleAddRemoveApp("add", { ...item }));
              //   }}
              onClick={() => {
                dispatch(edgeActions.handleShowEdgeInfo(true));
              }}
              sizeClass="medium"
              type="discreet-black"
            />
          </div>
          {edgesState.selectedEdge.rawData?.isUpdateAvailable ? (
            <div className="bread-crumb-cell">
              <div className="update-edge-section">
                <div className="update-lable">Update Available</div>
                <div className="update-icon-wrap">
                  <Button
                    icon="abb/refresh"
                    className="update-icon"
                    onClick={(e) => {
                      e.stopPropagation();
                      dispatch(
                        edgeActions.updateEdge(
                          edgesState.selectedEdge.rawData?.objectId
                        )
                      );
                    }}
                    sizeClass="medium"
                    type="discreet-black"
                  />
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          </React.Fragment>
}
        </div>
        <div className="screenTabs">
          <span
            className={
              confiurationView == true ? "viewOptions active" : "viewOptions"
            }
            onClick={() => setConfigurationView(true)}
          >
            Edge Configuration
          </span>
          {isValidUser && (
            <React.Fragment>

              <span className="seprator"></span>
              <span
                className={
                  confiurationView == false
                    ? "viewOptions active"
                    : "viewOptions"
                }
                onClick={() => {
                  setConfigurationView(false);
                  fetchSoftwaresData();
                  fettchCustomAppData();
                }}
              >
                Software Download
              </span>
            </React.Fragment>
          )}

        </div>
        {/* <div
          className={
            `edges-container`
          }
        > */}

        {showConfirmation && (
          <WarningDialog

            warningTitle={`Note: Downloaded software should be used for ${localStorage.getItem(
              "tenant"
            )} tenant only. Any action for redistribution/usage of software outside tenant scope is prohibited.`}
            warningMessage={DownloadContainer()}
            confirmButtonText="Copy Link"
            cancelButtonText={"Cancel"}
            onCancel={() => {
              setShowConfirmation(false);
              setDownloadLink("");
            }}
            onConfirm={() => {
              navigator.clipboard.writeText(downloadLink);
              setShowConfirmation(false);

            }}
          />
        )}
        {confiurationView ? (
          <div className="application-view">
            <div className="each-app-section installed-app-wrap">
              <h3 className="section-header">On This Edge</h3>
              <hr />
              {getApplicationList("thisEdge")}
              <div className="save-export-button-wrap">
                {/* <Button
                     text="Save"
                     type="primary-blue"
                     sizeClass="small"
                     //onClick={() => handleNewUser()}
                   /> */}
                <Button
                  text="Export Edge configuration"
                  type="primary-blue"
                  sizeClass="small"
                  onClick={() =>
                    dispatch(
                      edgeActions.exportAllApplications(edgesState.selectedEdge)
                    )
                  }
                />
              </div>
            </div>
            {/* <div className="separator"></div> */}
            <div className="each-app-section available-app-wrap">
              <h3 className="section-header">All Applications</h3>
              <hr />
              {getApplicationList("available")}
            </div>
          </div>
        ) : (
          <div className="softwareScreen">
            <div style={{ display: "flex", columnGap: "15px" }}>
              <h1>vE5000</h1>
              {/* <h4
                style={{
                  color: "blue",
                  display: "flex",
                  alignItems: "center",
                  columnGap: "5px",
                }}
              >
                <span
                  className="ABB_CommonUX_Button__icon"
                  style={{ marginTop: "4px" }}
                >
                  <i className="ABB_CommonUX_Icon__root">
                    <i className="ABB_CommonUX_Icon__icon_abb_16 ABB_CommonUX_Icon-16-style__icon-abb_information-circle-1_16"></i>
                  </i>
                </span>
                <span>
                  Edge configuration file should be exported to install the
                  software packages
                </span>
              </h4> */}
            </div>
            <hr />
            <div>
              <h3>Edgenius</h3>

              <table className="softwareTable">
                <tr>

                  <th style={{ width: "60%" }}>Name</th>
                  <th style={{ width: "20%" }}>Size</th>
                  <th style={{ width: "20%" }}>Release Date</th>
                </tr>
                {disconnectedEdgeSoftwares.length != 0 ? (
                  disconnectedEdgeSoftwares.map(
                    (val: softwareDetails, index) => {
                      return (
                        <tr key={index}>
                          <td style={{ width: "60%" }}>
                            <a
                              href="#"
                              onClick={() =>
                                getDownloadLinkforEdgenusSoftware(val.key)
                              }
                            >
                              {val.key}
                            </a>
                          </td>
                          <td style={{ width: "20%" }}>
                            {formatBytes(val.size)}
                          </td>
                          <td style={{ width: "20%" }}>
                            {val.lastModified.toString().split("T")[0]}
                          </td>
                        </tr>
                      );
                    }
                  )
                ) : (
                  <tr>
                    <td style={{ width: "60%" }}>No Data Found</td>
                    <td style={{ width: "20%" }}></td>
                    <td style={{ width: "20%" }}></td>
                  </tr>
                )}

              </table>
            </div>
            <hr style={{ backgroundColor: "red" }} />
            <div style={{ marginTop: "30px" }}>
              <h3>Extended Custom Application</h3>

              <table className="softwareTable">
                <tr>

                  <th style={{ width: "60%" }}>Name</th>
                  <th style={{ width: "20%" }}>Size</th>
                  <th style={{ width: "20%" }}>Release Date</th>
                </tr>
                {customExtendedSoftwares.length != 0 ? (

                  customExtendedSoftwares.map((val: softwareDetails, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <a
                            href="#"
                            onClick={() =>
                              getDownloadLinkforExtendedApps(val.key)
                            }
                          >

                            {val.key}
                          </a>
                        </td>
                        <td>{formatBytes(val.size)}</td>
                        <td>{val.lastModified.toString().split("T")[0]}</td>
                      </tr>
                    );
                  })

                ) : (
                  <tr>
                    <td style={{ width: "60%" }}>No Data Found</td>
                    <td style={{ width: "20%" }}></td>
                    <td style={{ width: "20%" }}></td>
                  </tr>
                )}

              </table>
            </div>
          </div>
        )}
        {/* </div> */}
        {edgesState.showEdgeInfo ? (
          <Dialog
            showCloseButton={true}
            closeOnEscape={true}
            closeOnLostFocus={false}
            dimBackground={true}
            isOpen={true}
            onClose={() => dispatch(edgeActions.handleShowEdgeInfo(false))}
            title={`${edgesState.selectedEdge.rawData.name}`}
            className="edge-details-modal"
          >
            <div className="edge-details-content">
              <EdgeInfo
                coreModules={edgesState.selectedEdge.rawData.coreModules}
                baseModules={edgesState.selectedEdge.rawData.baseModules}
                orchestratorModules={
                  edgesState.selectedEdge.rawData.orchestratorModules
                }
                bootstrapModules={
                  edgesState.selectedEdge.rawData.bootstrapModules
                }
              />
            </div>
          </Dialog>
        ) : (
          <></>
        )}
      </AppMainContent>
    </React.Fragment>
  );
};
export default withRouter(Applications);
