import React from "react";
import "./CommonLoader.css";
import { LoadingIndicator } from "@abb/abb-common-ux-react";

interface ICommonLoaderProps {
  isLoading?: boolean;
}

const CommonLoader = ({ isLoading = true }: ICommonLoaderProps) => {
  return <>{
    isLoading && (<div className="common-loader-wrap">
      <LoadingIndicator
        sizeClass="large"
        type="radial"
        color="blue"
        determinate={true}
      />
    </div>)
  }</>
};

export default CommonLoader;

