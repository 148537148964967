import React, { useEffect, useMemo, useState } from "react";
import { IAPPLicense } from "../Type/AppInfoTypes";
import ReactMarkdown from "react-markdown";
import "./AppLicence.css";
import { Button } from "@abb/abb-common-ux-react";

interface IAppLicense {
    releaseHistory: IAPPLicense[]
}

const AppLicense = (props: IAPPLicense) => {
    const [readMore, setReadmore] = useState<boolean>(false);
    let formattedDate = new Date().toLocaleString();
    formattedDate = formattedDate.replace(/\//g, "-");
    formattedDate = formattedDate.replace(/,/g, "");
    const licenceContent = useMemo(() => {
        return props.isLoading ? "Loading..." : atob(props.licenseText || "") || "Not configured.";
    }, [props.isLoading]);

    return <div className="column licence-container">
        <h3 className="section-header">LICENSING INFORMATION</h3>
        <hr />
        <div className="app-license-content" style={{ overflowY: readMore ? 'auto' : 'hidden' }}>
            <ReactMarkdown children={licenceContent} />
        </div>
        <div className="app-licence-footer">
            <Button
                type="discreet-blue"
                sizeClass="small"
                text={readMore ? "Read less" : "Read more"}
                onClick={() => setReadmore(!readMore)}
            ></Button>
        </div>
    </div>;
}

export default AppLicense;