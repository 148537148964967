import { Collapsible, CollapsibleContainer } from "@abb/abb-common-ux-react"
import React, { useEffect } from "react"

interface IEdgeInfoProps {
    orchestratorModules: [],
    coreModules: [],
    baseModules: [],
    bootstrapModules: []
}

export const EdgeInfo = ({ coreModules, baseModules, orchestratorModules, bootstrapModules }: IEdgeInfoProps) => {
    const [expanded, setExpanded] = React.useState<string[] | undefined>(undefined);
    useEffect(() => {
        console.log(coreModules);
    }, [coreModules, baseModules])
    return <CollapsibleContainer
        expandedCollapsible={expanded}
        singleOpenMode={true}
        onExpanding={
            expanded !== undefined ? (items) => setExpanded(Array.from(new Set([...expanded, ...items]))) : undefined
        }
        onCollapsing={expanded !== undefined ? (item) => setExpanded(expanded.filter((i) => i !== item)) : undefined}
    >

        <Collapsible
            key={'core-modules-key'}
            itemId={'coreModules'}
            title={'Core Modules'}
        >
            {coreModules.map((module: any, index: number) => (
                <Collapsible
                    key={`${module.name}-${index}`}
                    itemId={`${module.name}-${index}`}
                    title={`${module.name} ${module.tag}`}
                >
                </Collapsible>
            ))}
        </Collapsible>
        <Collapsible
            key={'base-modules-key'}
            itemId={'baseModules'}
            title={'Base Modules'}
        >
            {baseModules.map((module: any, index: number) => (
                <Collapsible
                    key={`${module.name}-${index}`}
                    itemId={`${module.name}-${index}`}
                    title={`${module.name} ${module.tag}`}
                >
                </Collapsible>
            ))}
        </Collapsible>
        <Collapsible
            key={'orchestrator-modules-key'}
            itemId={'orchestratorModules'}
            title={'Orchestrator Modules'}
        >
            {orchestratorModules.map((module: any, index: number) => (
                <Collapsible
                    key={`${module.name}-${index}`}
                    itemId={`${module.name}-${index}`}
                    title={`${module.name} ${module.tag}`}
                >
                </Collapsible>
            ))}
        </Collapsible>
        <Collapsible
            key={'bootstrap-modules-key'}
            itemId={'bootstrapModules'}
            title={'Bootstrap Modules'}
        >
            {bootstrapModules?.map((module: any, index: number) => (
                <Collapsible
                    key={`${module.name}-${index}`}
                    itemId={`${module.name}-${index}`}
                    title={`${module.name} ${module.tag}`}
                >
                </Collapsible>
            ))}
        </Collapsible>

    </CollapsibleContainer>
}