import React from "react";
import "./Footer.css";

const Footer = (props: any) => {
  React.useEffect(() => {
    const footerLogoElement = document.getElementById("footer-logo");
    if (footerLogoElement) {
      footerLogoElement.style.backgroundImage = "url('/ABB_logo_dark.svg')";
    }
  }, []);
  return (
    <div className="footer-wrap">
      <div className="copyright-label">{`©  ${new Date().getFullYear()} - Edgenius Configuration utility`}</div>
      <div className="abb-footer-logo" id="footer-logo"></div>
      {/* <AbbBar
        blackAndWhiteLogo={true}
        productName={`© 2021 - Edgenius - Principal Manager`}
      /> */}
    </div>
  );
};

export default Footer;
