import React, { useEffect, useMemo, useState } from "react";
import "./NewEdgeModal.css";
import { withRouter, RouteComponentProps } from "react-router-dom";
import {
  Dialog,
  Input,
  Dropdown,
  DropdownOption,
  Button,
} from "@abb/abb-common-ux-react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { IState } from "../../../Reducer";
import * as edgeActions from "../Action/ActionEdges";

const NewEdge = (props: RouteComponentProps) => {
  const edgesState = useSelector((state: IState) => state.edgesState);
  const [isValid, setIsValid] = useState<boolean>(true);
  const [edgeName, setEdgeName] = useState<string>("");
  const [isLoading, setIsloading] = useState<boolean>(false);
  const [edgeLocation, setEdgeLocation] = useState<string>("");
  const [edgeplatform, setEdgeplatform] = useState<string>("");
  const [edgeId, setEdgeId] = useState<string>("");

  let PlatformType =""

  const platformTypeString=(Ptype: string )=>{
    if ("offlineEdge" == Ptype) {
      return "Disconnected";
    }else if ("connectondemand" == Ptype){
      return "Connect-on-Demand";
    }else if ("connected" == Ptype){
      return "Connected";
    }else if("unknown"== Ptype){
      return "Not Configured";
    }
  }

  if(edgesState.selectedEdge.hasOwnProperty("rawData")){
    PlatformType = edgesState.selectedEdge.rawData.platformType;
  } else{
    PlatformType = `${platformTypeString("offlineEdge")}`
  }

  useEffect(() => {
    setEdgeName(edgesState.selectedEdgeName);
    setEdgeLocation(edgesState.selectedEdgeLocation);
    setEdgeplatform(edgesState.isEditEdge ? PlatformType : "offlineEdge");
    return () => {
      setIsloading(false);
    }
  }, []);

  const edgeNameList = useMemo(() => {
    const edgeName = edgesState.formattedEdgesData.map((edge) => {
      return edge.rawData.name;
    });
    return edgeName;
  }, [edgesState.formattedEdgesData]);

  const edgeIdList = useMemo(() => {
    const edgeName = edgesState.formattedEdgesData.map((edge) => {
      return edge.rawData.id;
    });
    return edgeName;
  }, [edgesState.formattedEdgesData]);

  const isdirty = useMemo(() => {
    return (
      edgeName.trim() !== edgesState.selectedEdgeName ||
      edgeLocation.trim() !== edgesState.selectedEdgeLocation
    );
  }, [edgeName, edgeLocation]);

  const dispatch = useDispatch();
  const handleCancel = () => {
    dispatch(edgeActions.hideNewEdgeModal());
  };
  const handleSubmit = () => {
    setIsloading(true);
    if (edgesState.isEditEdge) {
      const selected = edgesState.selectedEdge.rawData;
      dispatch(
        edgeActions.editEdge({
        id: selected.objectId,
        name: edgeName,
        location: edgeLocation,
        platformType: edgeplatform,
        })
      );
    } else {
      dispatch(
        edgeActions.createEdges({
        name: edgeName,
        location: edgeLocation,
        description: "",
        platformType: "Disconnected",
        })
      );
    }
  };
  return (
    <Dialog
      showCloseButton={true}
      closeOnEscape={true}
      closeOnLostFocus={false}
      dimBackground={true}
      isOpen={true}
      onClose={() => dispatch(edgeActions.hideNewEdgeModal())}
      title={edgesState.isEditEdge ? "Edit Edge" : "Add New Disconnected Edge"}
      className="create-edge-modal"
    >
      <div className="create-edge-form">
        <Input
          id="name"
          label="Name"
          dataType="text"
          required={true}
          instantValidation={true}
          maxLength={100}
          className="new-edge-name"
          validator={(value) => {
            const val = value?.trim();
            let valid = false;
            if (edgesState.isEditEdge) {
              valid =
                edgesState.selectedEdge.rawData?.name === val ||
                !edgeNameList.includes(val);
            } else {
              valid = !edgeNameList.includes(val);
            }
            setIsValid(valid);
            return { valid, text: "The edge name already exist" };
          }}
          showValidationBarWhenInvalid={true}
          showValidationIconWhenInvalid={!!edgeName && true}
          value={edgeName}
          onValueChange={(value: string) => setEdgeName(value)}
        />
        {/* <Input
          id="edgeId"
          label="Edge ID"
          dataType="text"
          className="new-edge-location"
          validator={(value) => {
            const val = value?.trim();
            let valid = false;
            if (edgesState.isEditEdge) {
              valid =
                edgesState.selectedEdge.rawData?.id === val ||
                !edgeIdList.includes(val);
            } else {
              valid = !edgeIdList.includes(val);
            }
            setIsValid(valid);
            return { valid, text: "The edge id already exist" };
          }}
          required={true}
          maxLength={100}
          showValidationBarWhenInvalid={true}
          value={edgeId}
          onValueChange={(value: string) => setEdgeId(value)}
        /> */}
        <Input
          id="location"
          label="Location"
          dataType="text"
          className="new-edge-location"
          required={true}
          maxLength={100}
          showValidationBarWhenInvalid={true}
          value={edgeLocation}
          instantValidation={true}
          onValueChange={(value: string) => setEdgeLocation(value)}
        />
        <Input
          id="platform"
          label="Connectivity Mode"
          dataType="text"
          className="new-edge-location"
          // required={true}
          maxLength={100}
          showValidationBarWhenInvalid={false}
          value={platformTypeString(edgeplatform)}
          disabled={true}
        />
        <div className="modal-submit-cancel">
          <Button
            sizeClass="medium"
            type="discreet-blue"
            className="btn-cancel-new-edge"
            onClick={handleCancel}
            text="Cancel"
          />
          <Button
            sizeClass="medium"
            type="primary-blue"
            onClick={handleSubmit}
            text={edgesState.isEditEdge ? "Update" : "Save"}
            className="btn-submit-new-edge"
            disabled={
              isLoading ||
              !isValid ||
              !isdirty ||
              edgeName.replace(/\s/g, "").length === 0 ||
              edgeLocation.replace(/\s/g, "").length === 0
            }
          />
        </div>
      </div>
    </Dialog>
  );
};

export default withRouter(NewEdge);
