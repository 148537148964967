import { Icon } from "@abb/abb-common-ux-react/components/Icon";
import React from "react"
import { useHistory } from "react-router";
import { IAppDetails } from "./Type/AppInfoTypes";

export const AppInfoHeader = (props: IAppDetails) => {
    const history = useHistory();

    return <>
    <div className="AppInfoHeader_model">
        <div className="browse-top-left">
                <div className="bread-crumb-cell bread-crumb-server"
                    onClick={() => {
                        history.goBack();
                    }}
                >{`Applications`}
                </div>
                <div className="bread-crumb-devider">/</div>
                <div className="bread-crumb-cell">
                    {props.name}
                </div>
            </div>
            <div className="main-heading-container">
                {/* <Icon name="abb/trend-2" sizeClass="medium" className="header-icon" /> */}
                <h1 className="main-heading">{props.name}</h1>
            </div>
            <div className="sub-heading-container">
                <h3>{props.description}</h3>
            </div>
    </div>
    </>
}