class DownloaderClass {
    data: string;
    constructor(data = "") {
        this.data = data;
    }

    download(filename = "install.package.yml", type_of = "text/plain") {
        let body = document.body;
        const a = document.createElement("a");
        a.href = URL.createObjectURL(new Blob([this.data], {
            type: type_of
        }));
        a.setAttribute("download", filename);
        body.appendChild(a);
        a.click();
        body.removeChild(a);
    }
    exportFile(data: string, edgeName: string) {
        this.data = data;
        const filename = `${edgeName}${new Date().getTime()}_install_packages.yml`
        this.download(filename)
    }
}
export const Downloader = new DownloaderClass();
