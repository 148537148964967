import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, withRouter } from "react-router";
import { getAllAppInfo } from "./Action/ActionAppInfo";
import { IState } from "../../Reducer";
import { AppInfoHeader } from "./AppInfoHeader";
import { AppMainContent } from "@abb/abb-common-ux-react";
import "./ApplicationInfo.css";
import AppReleaseNote from "./AppReleaseNote/AppReleaseNote";
import AppLicense from "./AppLicence/AppLicence";
import CommonLoader from "../../Common/Components/CommonLoader/CommonLoader";

const ApplicationInfo = () => {
    const dispatch = useDispatch();
    const appId = new URLSearchParams(useLocation().search).get("appId");
    const appInfo = useSelector((state: IState) => state.appInfoState);
    useEffect(() => {
        if (appId) {
            dispatch(getAllAppInfo(appId));
        }
    }, []);
    return (
        <AppMainContent className="app-info">
            <CommonLoader isLoading={appInfo.isLoading} />
            <AppInfoHeader {...appInfo.appDetails} />
            <div className="row">
                {/* <AppReleaseNote releaseHistory={appInfo.releaseHistory} /> */}
                <AppLicense licenseText={appInfo.licenceText} isLoading={appInfo.isLoading}></AppLicense>
            </div>
        </AppMainContent>
    );
}

export default withRouter(ApplicationInfo);