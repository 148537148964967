import React from "react";
import "./ViewsContainer.css";
import AppRoutes from "./Routes";
import Header from "../Common/Components/Header/Header";
import Footer from "../Common/Components/Footer/Footer";
import AppNotification from "../Common/Components/AppNotification/AppNotification";
import { AbbBar, AppContainer } from "@abb/abb-common-ux-react";
import { useSelector } from "react-redux";
import { IState } from "../Reducer";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { envSettings } from "../EnviornmentSettings/Settings";
import { commonAuthHelper } from "../App";
import TimeOutHandler from "../Common/Components/TimeOutHandler/TimeoutHandler";

const ViewsConatiner = (props: RouteComponentProps) => {

  const userLogout = () => {
    if (envSettings.connectivityMode === "connected") {
      localStorage.clear();
      sessionStorage.clear();
      document.location.replace("/");
    }
    commonAuthHelper.logOut();
  };
  const commonState = useSelector((state: IState) => state.commonState);
  return (
    <AppContainer
      className={`views-container ${commonState.theme === "light"
        ? "light-view-container"
        : "dark-view-container"
        }`}
      theme={commonState.theme}
    >
      {commonState.showNotification ? <AppNotification /> : <div />}
      <AbbBar
        blackAndWhiteLogo={false}
        productName="Edgenius Configuration Utility"
      />
      <Header />
      <AppRoutes />
      <TimeOutHandler onLogout={userLogout} />
      <Footer />
    </AppContainer>
  );
};
export default withRouter(ViewsConatiner);
