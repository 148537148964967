import { IFetchAllAppInfo, IReceivedAppDetails } from "../Type/AppInfoActionTypes";
import { IAppDetails } from "../Type/AppInfoTypes";

export const getAllAppInfo = (appId: string): IFetchAllAppInfo => {
    return {
        type: "FETCH_ALL_APPLICATION_INFO",
        appId
    }
}

export const setAppDetails = (appDetails: IAppDetails): IReceivedAppDetails => {
    return {
        type: "RECEIVED_APPLICATION_DETAILS",
        payload: appDetails
    }
}