import axios from "axios";
import { put, takeEvery } from "redux-saga/effects";
import * as apiEndPoints from "../../../Utils/ApiEndPoints";
import { IFetchAllAppInfo } from "../Type/AppInfoActionTypes";

function* fetchAllAppInfo(action: IFetchAllAppInfo): any {
    console.log("Executed..................", action)
    // yield put({
    //     type: "GET_APPLICATION_DETAILS",
    //     appId: action.appId
    // });
    yield put({
        type: "GET_APP_LICENCE_INFO",
        appId: action.appId
    });
    // yield put({
    //     type: "GET_RELEASENOTE_INFO",
    //     appId: action.appId
    // });
}

function* fetchAppInfo(action: any): any {
    try {
        const fetchedData = yield axios.get<any>(
            `${apiEndPoints.getAppDetails}`,
            { params: { applicationId: action.appId } },
        );
        yield put({
            type: "RECEIVED_APPLICATION_DETAILS",
            payload: fetchedData.data
        });
    } catch (err) {
        yield put({
            type: "FAILED_TO_FETCH",
        });
        if (err.message === 'unhandled') {
            yield put({
                type: "SHOW_NOTIFICATION",
                notificationType: "alarm",
                message: "Failed to fetch application information. Please try again later, or contact your system administrator if the problem persists.",
            });
        }
    }
}

function* fetchLicenseInfo(action: any): any {
    try {
        console.log("APPType: ", action.appId);
        const fetchedData = yield axios.get<any>(
            `${apiEndPoints.getAppLicenceInfo}`,
            { params: { applicationType: action.appId } },
        );
        yield put({
            type: "RECEIVED_APP_LICENCE_INFO",
            payload: fetchedData.data.licenseText
        });
    } catch (err) {
        yield put({
            type: "FAILED_TO_FETCH",
        });
        if (err.message === 'unhandled') {
            yield put({
                type: "SHOW_NOTIFICATION",
                notificationType: "alarm",
                message: "Failed to fetch license information. Please try again later, or contact your system administrator if the problem persists.",
            });
        }
    }
}

function* fetchReleaseInfo(action: any): any {
    try {
        const fetchedData = yield axios.get<any>(
            `${apiEndPoints.getAppReleaseNoteHistory}`,
            { params: { applicationType: action.appId } },
        );
        yield put({
            type: "RECEIVED_RELEASENOTE_INFO",
            payload: fetchedData.data
        });
    } catch (err) {
        yield put({
            type: "FAILED_TO_FETCH",
        });
        if (err.message === 'unhandled') {
            yield put({
                type: "SHOW_NOTIFICATION",
                notificationType: "alarm",
                message: "Failed to fetch release notes information. Please try again later, or contact your system administrator if the problem persists.",
            });
        }
    }
}

export default function* actionWatcherAppInfo() {
    yield takeEvery("FETCH_ALL_APPLICATION_INFO", fetchAllAppInfo);
    yield takeEvery("GET_APPLICATION_DETAILS", fetchAppInfo);
    yield takeEvery("GET_APP_LICENCE_INFO", fetchLicenseInfo);
    yield takeEvery("GET_RELEASENOTE_INFO", fetchReleaseInfo);
}
