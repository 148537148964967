import * as edgeTypes from "../Type/TypeEdges";
import * as commonTypes from "../../../Common/Type/TypeCommon";
import _ from "underscore";

export const edgesTableHeader: commonTypes.ITableHeaderObj[] = [
  {
    id: "name",
    displayName: "Edge Name",
    className: "edges-list-header",
    isSortable: false,
  },
  // {
  //   id: "id",
  //   displayName: "Edge ID",
  //   className: "edges-list-header",
  //   isSortable: false,
  // },
  {
    id: "location",
    displayName: "Location",
    className: "edges-list-header",
    isSortable: false,
  },
  {
    id: "platformType",
    displayName: "Connectivity Mode",
    className: "edges-list-header",
    isSortable: false,
  },
];

export const formatEdgesData = (
  rawUserData: edgeTypes.IRawEdgeObj[]
): commonTypes.IEachRowObj[] => {
  const formattedData: commonTypes.IEachRowObj[] = [];

  rawUserData.map((item) => {
    const rowObj: commonTypes.IEachRowObj = formatEdgeData(item);
    // rowObj.rawData.isUpdateAvailable = true;
    formattedData.push({ ...rowObj });
  });
  // console.log('Edge Data', formattedData);
  return formattedData;
};

export const formatEdgeData = (item: edgeTypes.IRawEdgeObj) => {
  const keysToBeMapped = ["name", "location", "platformType"];
  const rowObj: commonTypes.IEachRowObj = {
    id: "",
    data: [],
    rawData: { ...item },
  };
  rowObj.id = item.objectId as string;
  rowObj.isClickable = true;
  rowObj.isDeleteActionEnabled = true;
  rowObj.isViewActionEnabled = true;
  rowObj.isEditActionEnabled = true;
  rowObj.data = [];
  rowObj.additionalInfo = { ...item };
  rowObj.additionalInfo.platformType = item["platformType"];
  if (item["platformType"]=="offlineEdge") {
    item["platformType"] = "Disconnected";
    rowObj.additionalInfo.platformType = "Disconnected Edge";
  }else if (item["platformType"]=="connectondemand"){
    item["platformType"] = "Connect-on-Demand";
    rowObj.additionalInfo.platformType = "COD Edge";
  }else if (item["platformType"]=="connected"){
    item["platformType"] = "Connected";
    rowObj.additionalInfo.platformType = "Connected Edge";
  }else if (item["platformType"]=="unknown"){
    item["platformType"] = "Not Configured";
    rowObj.additionalInfo.platformType = "Not Configured Edge";
  }
  keysToBeMapped.map((key) => {
    const dataObj: commonTypes.IEachCellObj = {
      id: "",
      rawVal: "",
      formattedVal: "",
    };
    dataObj.id = key;
    dataObj.rawVal = `${item[key]}`;
    dataObj.formattedVal = `${item[key]}`;
    rowObj.data.push({ ...dataObj });
  });
  rowObj.rawData.coreModules = formatModuleData(item["platformModules"]);
  rowObj.rawData.baseModules = formatModuleData(item["baseModules"]);
  rowObj.rawData.orchestratorModules = formatModuleData(
    item["orchestratorModules"]
  );
  rowObj.rawData.bootstrapModules = formatModuleData(item["bootstrapModules"]);
  return rowObj;
};

function formatModuleData(data: any) {
  let modules: any[] = [];
  if (data && data.modules) {
    Object.values(data.modules).forEach((item: any) => {
      // console.log(Object.values(item.modules))
      modules = modules.concat(Object.values(item.modules));
    });
  }
  return modules;
}

export const formatApplicationData = (
  rawUserData: any
): edgeTypes.IApplicationObj[] => {
  const formattedData: edgeTypes.IApplicationObj[] = [];
  rawUserData.map((item: any) => {
    const rowObj: edgeTypes.IApplicationObj = {
      id: "",
      name: "",
      model: "",
      type: "",
      isInstalled: false,
      installedOn: "",
      isExported: false,
      isUpdateAvailable: false,
      rawObj: {},
      licensesPath: ""
    };
    rowObj.id = item.type;
    rowObj.name = item.name;
    rowObj.model = item.model;
    rowObj.type = item.type;
    rowObj.isInstalled = item.isInstalled;
    rowObj.installedOn = item.installedOn;
    rowObj.isExported = item.isExported;
    rowObj.isUpdateAvailable = item.isUpdateAvailable;
    rowObj.rawObj = item;
    rowObj.licensesPath = item.licensesPath;
    formattedData.push({ ...rowObj });
  });
  // console.log(formattedData);
  return formattedData;
};
